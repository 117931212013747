.id_container
{
    width: 450px;
    height: 320px;
    border-radius: 5px;
    display: flex;
    border:2px solid lightblue;
    margin:  10% auto;
    flex-direction: column;
}
.top_Id_container
{
    width: 100%;
    display: flex;
    height: 25%;
    background-color: #3165a8;
    padding: 5px 10px;
    border-radius: 3px;
}
.middle_ID_container{

    height:70% ;
    display: flex;
    padding: 10px;
    /* background-color: rgba(61,123,188,0.5); */
    background: linear-gradient(720deg, rgba(61,123,188,0.5), white, #3165a8);}
.ID_profile_container
{
    height: 150px;
    display: flex;
    flex-direction: column;
}
.ID_profile_container>div
{
    display: flex;
    justify-content: center;
}
.Id_content_text
{
    margin: 0px 8px;
    font-weight: 700;
    font-size: 15px;
    color:black;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.bottom_Id_container
{
    width: 100%;
    height: 5%;
    background-color: #3165a8;
    padding: 20px;
    border-radius: 3px;

}
.id_logo_container
{
    height: 100%;
    
}
.Id_detail_contianer
{
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px 10px 10px 10px;
}
.Id_detail_contianer>div
{
    display: flex;
    width: 100%;
    margin-bottom:15px ;
    justify-content: flex-start;
    align-items: center;
   
}
.Id_detail_contianer>div>div
{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    
}
.ID_profile_container
{
 height: 100%;
 display: flex;
 flex-direction: column;
}
.id_profile_pic
{
    max-height: 120px;
    object-fit: contain;

}
.top_id_title_container
{
 display: flex;
 align-items: center;
 width:  100%;
 justify-content: center;

}
.top_id_title_container>h5
{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 25px;
    color: white;
    width: 100%;
    text-align: center;
    font-weight: 500;
}
.id_logo
{ 
    height: 60px;
    border-radius: 5px;
    object-fit: contain;
   

}
