.card_container
{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: rgb(23,56,61);
    transition: 0.5s ease-in-out;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
}
.text_color
{
    color: rgb(31, 78, 85);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
}
.card_container:hover
{
    scale: 108%;
    margin: 10px ;
    border: 0.1px solid rgb(31, 78, 85);
}
.dropdown-zindex
{
    z-index: 100;
}