.about_us_page_title_container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ecf6fe;
}
.aboutuspage_content_container
{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: flex-start;
 padding: 10px;
 height: 100%;

}
.aboutuspage_content_container>h6
{
    color: rgba(11,96,165,0.9)!important;;
    font-weight: 700;
    font-size: 20px;
    font-family: sans-serif;
    margin: 15px 0px;
}
.aboutuspage_content_container>h3
{
    color:black;
    font-weight: 700;
    font-size: 30px;
    font-family: sans-serif;
    margin: 15px 0px;
}
.aboutuspage_content_container>p
{
    color:gray;
    font-weight: 500;
    font-size: 16px;
    margin: 10px 0px;
    font-family: sans-serif;
    line-height: 30px;
}
.about_us_page_title_container>p>span
{
    color:gray;
    font-weight: 800;
    font-size: 18px;
    margin: 15px 0px;
    font-family: sans-serif;
    line-height: 20px;
}