

.login_page_logo{
    object-fit: contain;
    height: 120px;
    margin: 15px auto;

}
.lg_page_left_container
{
    padding: 20px;
}
.lg_page_left_container>img{
    margin: auto;
}
.lg_page_right_container
{
    background-color: #545454;
    border-radius: 8px;
    padding: 20px;
    color:white;
   
    
    
}
.form-label{
    color:white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
}

.login_inputs{
    border: none;
    border-bottom: 0.1px solid white;
    border-radius: 0px;
    padding: 20px;
    color:white;
   transition: 0.5s ease-in-out;
    height: 40px;
    background-color: #545454;

}
.login_inputs:focus{
    outline: none;
    border: none;
        text-decoration: none;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.5);
        border-radius: 0.5rem;
        padding: 20px
      
      
}

.login_inputs::placeholder{
    color :white;

}
.welcome_text>h4
{
    font-size: 35px;
    font-weight:700;
    font-family: Arial, Helvetica, sans-serif;
}
.welcome_text>h6
{
    font-size: 12px;
    font-weight:300;
    font-family: Arial, Helvetica, sans-serif;
    color: lightgray;
}
.forgot_password
{
    color: lightgray;
    cursor: pointer;
    font-size: 14px;
    font-weight: 300;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.forgot_password:hover
{
    font-size:16px;
    font-weight: 600;
    color:rgb(175, 226, 243)
}
.password_icon
{
    background-color:#545454 !important;
    color: white;
    border: none !important;
    border-bottom: 0.1px solid white ;
    
}
.password_icon_container
{
    background-color:#545454;
    border:none;
    border-bottom: 0.1px solid white;
    border-radius: 0px;
}
.signup_link{
    font-size: 14px;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: lightgray;
}
.signup_links
{
    text-decoration: underline;
    font-size: 16px;
    color: lightgray;
    margin-left: 10px;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.signup_links:hover
{
    font-weight: 700;
    
    color: white;
}