.conduct_card_container
{   
   
    --text-color: black;
    --title-text: #043c6d;;
    transition: 0.5s ease-in-out;
    --background-color: white;
    cursor: pointer;
    
    background-color: var(--background-color); 
 }
 .pricing_container
 {
    cursor: pointer;
    transition: ease-in-out 0.5s;
    width: 100%;
 }
 .pricing_container:hover
 {
    transform: scale(105%)
 }
 .pricing_container:hover .currency_sign
 {
   color:#3f8755 ;
   font-weight: 700;
 } 
 .pricing_container:hover .pricing_titles
 {
   color:#043c6d ;
   font-weight: 700;
 } 
 .pricing_container:hover .pricing_details_container>ul>li>h4
 {
     color:#043c6d;
     font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     font-weight: 500;
 
 }
.pricing_card_title{
    font-size: 35px;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    color:#043c6d;
    transition: 0.5s ease-in-out;
    background-color: var(--background-color);
    
}
.pricing_titles
{
    font-size: 18px;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    color:#3c6efd;
    transition: 0.5s ease-in-out;
    background-color: var(--background-color);
}
.pricing_details_container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
    margin-top: 10px;
}
.pricing_details_container>div>h5
{
    margin-top: 5px;
    color:lightgray;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    font-weight: 500;
}
.pricing_details_container>ul>li>h4
{
    margin-top: 5px;
    color:lightslategray;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    transition: ease-in-out 0.5s;
    font-weight: 400;
}
.currency_sign
{
    color: #3060ad;
    font-size: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    transition: ease-in-out 0.5s;

}
.pricing_title_hidden
{
    visibility: hidden;
    opacity: 0;
    transform: translatex(-100px);
    transition: ease-in-out 3s;
}
.pricing_hidden
{
    visibility: hidden;
    opacity: 0;
    transform: translateY(100px);
    transition: ease-in-out 3s;
}
.pricing_fade_in
{   visibility: visible;
    opacity: 1;
    transform: translateX(0px);
    transform: translateY(0px);
}
