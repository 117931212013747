
.footer_container
{   bottom:0 ;
  padding: 20px;
    background-color: #065fad;
    box-sizing:content-box;
}
.footer_title
{
    font-size: 25px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:white;
    padding: 5px;
    width: 300px;
    margin: auto auto;
    text-align: start;
}
.links_container
{

    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content:baseline;
    align-items: center;
    height: auto;
    
}

.links_container>div
{   display: flex;
    color:white;
    align-items: center;
    padding: 5px;
    width: 300px;
    justify-content: flex-start;
}
.links_container>div>.links
{   display: flex;
    text-decoration: none;
    color:white;
    align-items: center;
    padding: 5px;
    width: 300px;
    justify-content: flex-start;
}
.links_container>div>h6
{
    margin-left:10px ;
    cursor: pointer;
    font-size: 14px !important;
    margin-top: 3px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400px;
    
}
.links_container>div>.links>h6
{
    margin-left:10px ;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px !important;
    margin-top: 3px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400px;
    
}
.footer_logo
{
    height: 70px;
    object-fit: contain;
}
.social_media_container
{
    display:flex;
    padding: 10px;
    margin-top: 20px;

}
.social_media_icon_container>a
{
    margin: 2px 5px;
    cursor: pointer;
    color: white !important;
    padding: 5px;
    transition: ease-in-out 0.5s;
}
.social_media_icon_container>a:hover
{
    background-color: white;
    border: 0.1px solid #F6b024;
    color:#2998ED !important;
    border-radius: 50%;
    font-size: large;
}
.footer_first_section>h4
{
    padding: 10px;
    font-size: 30px;
    font-weight: 600;
    color: white;
    width: 70%;
   
    margin-top: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


}
.footer_first_section>p
{
    color:lightgray;
    width: 80%;
   
}
.links_container>div:hover>h6
{
    text-decoration: underline;
    font-size: 16px;
    font-weight: bold;
    color: #f6b024;
}
.links_container>div:hover>.links>h6
{
    text-decoration: underline;
    font-size: 16px;
    font-weight: bold;
    color: #f6b024;
}
.siteownership_container
{
    background-color: #05579e;
    width: 100%;
    align-items: center;
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content:center;
    
}
.siteownership_container>h5,h6
{
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
    font-weight: 400;
}
.siteownership_container>h6>a>span
{
    font-size: 14px;
    text-decoration: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #F6b024;
    font-weight: 700;
}