.partner-slider-container {
    width: 100%;
    padding: 20px;
    margin: 0 auto;
  }
  
  .partner-logo {
    display: block;
    max-height: 100px; /* Adjust the logo height */
    object-fit: contain;
  }
  
  .swiper-button-prev, .swiper-button-next {
    color: #000; /* Customize arrow color */
  }
  
  .swiper-pagination-bullet {
    background-color: #ccc;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #000;
  }