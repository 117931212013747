#topbar
{
height: 60px;
  padding: 0;
  font-size: 14px;
  transition: all 0.5s;
  overflow: hidden;
  color: rgba(255, 255, 255, 1);
  z-index: 990;
  background-color: #0b6bb7;
}
.contact-info
{
    display: flex;
    color :yellow
    
}
.contact-info>a
{
    color:yellow
}
.nav_bar_logo
{
    object-fit: contain;
    height: 50px;
}
.nav_link{
    --text:white;
    --text-inverse:white;
    --background:red;
    --hover-text:black;
    --hover-border:rgb(238, 194, 20);
    font-size:14px ;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    color:var(--text);
    background-color: transparent;
    text-align: center;
    text-decoration: none;
    border-width: 1px;
    
    
}

.nav_scrolled
{
    transition: 0.5s ease-in-out;
    --text:rgb(238, 194, 20);
    --background: rgba(11,96,165,1);
    --hover-text:rgb(255, 255, 255);
    background-color: rgba(11,96,165,0.9)!important;
    --hover-border:white


}
.nav_scrolled_link
{
    transition: 0.5s ease-in-out;
    --text:rgb(238, 194, 20);

    --hover-text:rgb(255, 255, 255);
    --hover-border:white


}
.nav_background
{
    background-color:rgba(11, 108, 184,0.8);
    background-color:rgba(10,96,164,0.8)!important;
    border :none ;
}
.nav_link:hover
{
    border:none;
    color:var(--hover-text);
    border-bottom: 0.1px solid var(--hover-border) ;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;

    
}
.modal_text
{
    color: #043c6d;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
}
.form_label
{
    color: #043c6d;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     
}