.single_container
{
    display:flex;
    flex-direction: column;
    padding: 5px;
    width: 300px;
    margin: 10px 0px;
    height: 100%;
    align-items: center;
    justify-content: center;
    border:5px solid lightblue
}
.members_profile_container
{
    max-height: 250px;
    
}
.members_profile_container>img
{
    max-height: 250px;
    object-fit: contain;
}
.members_detail
{
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
}
.members_detail>h5{
    color:#2d5baa;
    font-weight: 700;
    margin: 10px 0px;
}
.members_detail>h6{
    color:black;
    font-weight: 600;
    font-size: 18px;
    margin: 10px 0px;
}
.see-profile-button{
    padding: 10px;
    background-color:#eec214;
    border:none;
    color:#2d5baa;
    float: right;
    margin-top: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 500;
    transition: ease-in-out 0.5s;
    
}
.see-profile-button:hover{
    color: black;
    padding: 15px;
}