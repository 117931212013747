.objective_section_container
{
    display: flex;
    
    flex-direction: column;
    padding: 10px;
    cursor: pointer;
    margin: 5px 0px;
    height: 100%;
    
}
.section_title
{
    color:#043c6d;
    font-size: 35px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    width: 100%;
    visibility: hidden;
    transform: translateX(100px);
    opacity: 0;
    transition: ease-in-out 0.5s;
    text-align: center;
}
.objective_section_container>div
{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}
.objective_section_container>div>h6
{
    font-size: 14px;
    transition: ease-in-out 0.5s;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    color: gray;
    margin-top: 10px;
}
.objective_section_container>div>h5
{
    font-size: 20px;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    color:#043c6d;
    width: 100%;
    margin-top: 10px;
}
.objective_media
{ 
    object-fit: contain;
    max-height: 300px;
    transition: ease-in-out 1s;
}
.objective_section_container:hover.objective_section_container>div>h6
{
    font-size: 14px;

    font-weight: 500;
    color: #043c6d;
    margin-top: 30px;
}
.objective_section_container:hover>div>.objective_media
{
    transform: scale(110%);
    
}
.objective_media_container
{
  
}
.objective_content_section
{
    visibility: hidden;
    transform: translateY(100px);
    opacity: 0;
    transition: ease-in-out 0.5s;
}

.objective_fade_in
{
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    transform: translateX(0px);
}


