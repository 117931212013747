.Page_title_container
{
    display: flex;
    padding: 15px;
    width: 100%;
    background-color: #ecf6fe;
}
.Page_title_container>div>h6
{
    width: 100%;
    text-align: start;
    color:#6fc0f8;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    padding: 2px;
}
.Page_title_container>div>h5
{
    width: 100%;
    text-align: start;
    color:#043c6d;
    font-size: 18px;
    padding: 2px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
}
.contact_page_img_container
{
    max-width:  360px;
    margin: auto;
    border: none;
     
}
.contact_person_name
{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 25px;
    font-weight: 500;
    color: #043c6d;

}
.contact_person_title
{
    font-size: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    color: gray;
}
.contact_person_quote
{
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    color: gray;
}
.contact_social_media_icon_container
{
    margin-right:30px ;
    cursor: pointer;
    color:#043c6d !important;
    padding: 3px;
    font-size: 5px !important;
    transition: ease-in-out 0.5s;
}
.contact_social_media_icon_container:hover
{
    background-color: white;
    border: 0.1px solid #F6b024;
    color:#2998ED !important;
    border-radius: 50%;
    font-size: large;
}
.contact_us_icons
{
    cursor: pointer;
    font-size: 5px !important;
    color:#043c6d !important;
}

