.admin_login_container
{
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: rgb(219,235,211);
    height: 100%;
    margin-top: 10px;
    margin: auto;
    max-width: 400px;

}
.full-height
{
    max-height: 100vh !important;
}
.admin_login_lable
{
    color:black;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    margin: 5px 0px;
}
.admin_login_inputs
{
    background-color: rgb(219,235,211);
    outline: none;
    border: none;
    border-bottom: 0.1px solid gray;
    border-radius: 0px !important;
    
}
.admin_login_inputs:focus-visible
{
    outline: gray;
    border:none
}
.admin_password_icon_container
{
 background-color: rgb(219,235,211);
 color:black;
 border-bottom:0.1px solid gray;
 border-radius: 0px;
}
.admin_password_icon_container>.password_icon
{
    background-color: rgb(219,235,211)!important;
 color:black
}
