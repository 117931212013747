.profile_update_container
{   
    padding: 20px 30px;
    display: flex;
  
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.profile_image_container
{
    height: 230px;

    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 0px;
    
    border:5px solid #52ad49
}
.profile_image_container>img
{
    object-fit: fill;
    height: 220px;
  
    
}
.profile_info_container
{
    display:flex;
    width: 100%;
    word-wrap:break-word;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    
}
.profile_info_container>div
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    border-bottom: 0.1px solid lightgray
}
.profile_info_container>div>h6
{
   color: #043c6d;
   font-size: 18px;
   font-weight: 500;
   font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.profile_info_container>div>h6>span
{
    color: gray;
   font-size: 14px;
   font-weight: 500;
   margin-left: 10px;
   font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}