
.material_contianer
{
    display: flex;
    justify-content: space-around;
    width: 100%;
    border:0.1px solid lightblue
}
.download_button
{
    font-size: 70px;
    color:lightblue;
    transition: ease-in-out 1s;
    cursor: pointer;
}
.download_button:hover{
    font-size: 90px;
    color:#3c6efd;

}

.download_text{
    font-weight: 500;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:#244a8c;
    transition: ease-in-out 0.8s;
}
.download_button_container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.material_info
{   display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    word-wrap: break-word; 
    flex: 0.9;
    border-left: 0.1px solid lightblue;
}
.material_info>h4
{
    color:gray;
    word-wrap: break-word; 
    margin: 10px 0px;
    font-weight: 400;
    font-size: 18px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.material_info>h5
{
    color:gray;

    margin: 5px 0px;
    font-weight: 400;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.material_details
{
    display: flex;
    width: 90%;
    justify-content: space-around;
    align-items: flex-start;
}
.material_info>.material_details>div
{
    display: flex;
    width: 100%;
    justify-content: start;
}
.material_content{
    display:flex ;
    margin-top: 10px;
    align-content:flex-start;
    justify-content: flex-start;
}
.matarial_catagory_container
{
    display:flex;
    flex-direction: column;
    gap: 0.5;
}
.matarial_catagory_container>div
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
    padding: 10px;
    border: 0.1px solid lightblue;
    border-radius: 5px;
    cursor: pointer;
    transition: ease-in-out 0.5s;
}
.matarial_catagory_container>div>h6
{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#3c6efd;
}

.matarial_catagory_container>div:hover
{
    background-color: #3c6efd;
    color :white;
    padding: 15px;

}
.matarial_catagory_container>div:hover>h6
{
    
    color :white;
    font-weight: 500;


}
/* Center the loading spinner and make it very large */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.large-spinner {
    width: 80px;
    height: 80px;
}
