.announcement_container
{
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
}
.announcement_color
{
    background-color: rgba(11,96,165,0.9)!important;

}
.announcement_container>h3
{
    color:white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    margin: 20px 0px;

}
.announcement_container>div
{
    color :white;
    font-size: large;
    margin-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}