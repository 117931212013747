.Title_header_container
{
    background-color: rgb(219,235,211)!important;
    color:rgb(23,56,61);
}
.search_bar_inputgroup
{
    background-color: rgb(219,235,211);
    outline: none;
    border: none;
    border-bottom: 0.1px solid rgb(23,56,61);
    border-radius: 0px !important;
    color: rgb(23,56,61);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
}
.search_icon_container
{
 background-color: rgb(219,235,211);
 color:rgb(23,56,61);;
 border-bottom:0.1px solid rgb(23,56,61);
 border-radius: 0px;
 padding: 10px;
}
.search_icon_container>.search_icon
{
    background-color: rgb(219,235,211)!important;
 color:rgb(23,56,61);
}
.search_icon_container:hover>.search_icon
{
    transform: scale(125%);
}
.search_bar_inputgroup:focus
{
    outline: none;
}
.text-color
{
    color:rgb(23,56,61) !important;
}
.tss-hj53wm-MUIDataTableToolbar-titleText
{
    color:rgb(23,56,61) !important;
    font-weight: 600 !important;
}
.message_modal_container
{
        display: flex;
        align-items: center;
        gap: 40px;
        padding: 3px;
        justify-content: flex-start;
}