.main_title{
    font-size: 40px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    color: #545454;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
    
}
.intro_paragraph
{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 16px;
    word-spacing: 0.2cap;
    color: Black;
}

.intro{
    visibility: hidden;
    opacity: 0;
    transform: translateY(100px);
    transition: ease-in 1s;
}
.intro_loading
{
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
}
.carousel_container
{
    background-image:url("/public/Assets/hero-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 80vh;
    position: relative;
    display: flex;
    padding: 0px;
    margin-top: -75px;
    justify-content: center;
    align-items: center;
    
}
.carousel_container>div
{
    background-color:rgba(11, 108, 184,0.8);
    width: 100%;
    height: 100%;
    padding: 100px 5px;
    
}
.carousel_item
{
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto auto;
  
}
.carousel_item>div
{
  
    width: 100%;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
