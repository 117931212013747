.register_title
{
    font-size: 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    color: #043c6d;
    width: 100%;
    text-align: center;
    margin-top: 20px ;
}
.benefits_container
{
    margin-top: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.benefits_container>h6
{
    font-size: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    color:#043c6d;
    margin: 10px;
    width:100%;
    text-align: center;
    text-decoration: underline #043c6d ;
}
.benefits_container>div
{
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    margin-top: 5px;
    color:#043c6d
    
}
.benefits_container>div>h6
{
    color:#043c6d;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
}

.benefits_container>div:hover>h6
{
    color:rgb(238, 194, 20);
}
.backto_login_link
{
    font-size: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    text-align: center;
    width: 100%;
    text-decoration: none;
    color:gray;
    transition: ease-in-out 0.3sec;
}
.backto_login_link:hover
{
    color:#043c6d;
    font:18px;
    font-weight: 700;
    text-decoration: underline;
}