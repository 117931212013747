.navback_cover
{
    margin-top: -80px;
    background-color:rgba(11, 108, 184,0.8);
    height: 11vh;
    width: 100%;
}
.blog_title_container
{
    display: flex;
    padding: 15px;
    width: 100%;
    background-color: #ecf6fe;
}
.blog_title_container>div>h6
{
    width: 100%;
    text-align: start;
    color:#6fc0f8;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    padding: 2px;
}
.blog_title_container>div>h5
{
    width: 100%;
    text-align: start;
    color:#043c6d;
    font-size: 18px;
    padding: 2px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
}
.single_blog_container
{
    display: flex;
    flex-direction: column;
}
.single_blog_container>img
{
   
    object-fit: contain;
}
.blog_details_content>p{
    -webkit-line-clamp: 3;
    overflow: hidden;
    color:lightgray;
    -webkit-box-orient: vertical;
   
  
}
.single_blog_container>h4
{
    text-align: start;
    padding: 10px 20px;
    margin-top: 10px;
    text-wrap: wrap;
    color:#043c6d;
    font-size: 25px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.blog_info_container
{
    display: flex;
    flex-direction: column;
    padding: 5px 20px;
    justify-content: flex-start;
    margin-top: 1px;
}
.blog_details
{
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    
 
}
.blog_details>div
{
    display: flex;
    margin-right:  30px;
}
.blog_details>div>h6{
    font-size: 15px;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:#8d79a7
}

.blog_details_icons
{
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #2b99f8!important;
    margin-right: 10px;
}
.blog_info_container>div>p{
    color: gray;
    font-size: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
}
.blogs_right_container
{   
    display: flex;
    flex-direction: column;
    padding: 15px

}
.blogs_right_container>div>h5
{
    font-size: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    color:#043c6d;
    padding: 10px;
}
.search
{
    background-color: #F6b024 !important;
    color: white !important;

}

.recent_post_Img
{
   height: 70px;
   object-fit: contain;
}
.blogs_right_container>div>div>h6
{
    font-size: 15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    color:#043c6d;
    padding: 10px;
}



