.contact_us_title
{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 35px;
    font-weight: 500;
    width:100%;
    text-align: center;
    color:#0e2c6d;

}
.send_message
{
    color: white;
    background-color:#2370ae;
    padding: 10px;
    border-radius:5px ;
    font-weight: 600;
    width:100% ;
    margin:20px auto;
    cursor: pointer;
    border: none;
    transition: ease-in-out 0.5s;
}
.send_message:hover
{
    background-color: #198754;
    transform: scale(102%);
}
.contact_us_items
{
    padding:20px;
    height: 100%;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.contact_us_icon
{
    font-size: 40px !important;
   
    color:#2370ae;
}
.contact_us_items>h4
{
    font-size: 18px;
    margin-top: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:#0e2c6d;
}
.contact_us_items>h5
{
    font-size: 15px;
    font-weight: 400;
    margin-top: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:gray
}
.contact_us_form
{
    visibility: hidden;
    opacity: 0;
    transform: translateX(100px);
    transition: ease-in 3s;
}
.contactUs_firstSection
{
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100px);
    transition: ease-in 3s;
}
.contactUs_secondSection
{
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100px);
    transition: ease-in 3s;
}
.contactUs_thirdSection
{
    visibility: hidden;
    opacity: 0;
    transform: translateY(100px);
    transition: ease-in 3s;
}
.contactUs_fourthSection
{
    visibility: hidden;
    opacity: 0;
    transform: translateY(100px);
    transition: ease-in 3s;
}
.contact_us_fade_in
{
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    transform: translateX(0px);
}