.conduct_card_container
{   
   
    --text-color: black;
    --title-text: #043c6d;;
    transition: 0.5s ease-in-out;
    --background-color: white;
    cursor: pointer;
    
    background-color: var(--background-color); 
 }
 .conduct_card_style
 {  
    background-color: var(--background-color);
    border:none;
    transition: 0.5s ease-in-out;
    

 }
 .conduct_container.conduct_card_style:nth-child(1){
    --index:1;
 }
 .conduct_container.conduct_card_style:nth-child(2){
    --index:2;
 }
 .conduct_container.conduct_card_style:nth-child(3){
    --index:3;
 }
.conduct_card_title{
    font-size: 35px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    color:var(--title-text);
    transition: 0.5s ease-in-out;
    background-color: var(--background-color);
    
}
.conduct_card_body
{
    color:var(--text-color);
    font-size: 16px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
}

.conduct_card_style:hover
{
    margin-bottom: 20px;
    transform: scale(108%);
    --text-color:#0e2c6d;
    --title-text:#0e2c6d;
    --background-color:white ;
    border:0.1px solid #0e2c6d;
    
}
.conduct_title
{
    color:black;
    visibility: hidden;
    opacity: 0;
    transform: translatex(100px);
    transition: ease-in 1s;
}
.conduct_title_loading
{
    visibility: visible;
    opacity: 1;
    transform: translateX(0px);
}

.conduct_container
{
    visibility: hidden;
    opacity: 0;
    transform: translateY(100px);
    transition: ease-in 3s;
   
    
}

.conduct_card_style_fadeIn
{
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
}
.conduct_title>h3{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 35px;
    font-weight: 500;
    color:#0e2c6d

}
